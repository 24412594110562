import React from "react"
import { SanityMedia } from "../../sanity/types"
import Image from "../image/Image"
import { generateSanityImage } from "../../sanity/image"
import VideoSmart from "../video/VideoSmart"
import VideoLightweight from "../video-lightweight/VideoLightweight"

interface MediaProps {
    media: SanityMedia
    alt?: string
    sizes: string[]
    width?: number
    height?: number
    step?: number
    minWidth?: number
    minHeight?: number
    ignorePlaceholder?: boolean
    format?: "jpg" | "png"
    videoWithoutBorders?: boolean
}

export default function Media({
    media,
    height,
    width,
    sizes,
    step,
    minHeight,
    minWidth,
    ignorePlaceholder,
    format,
    alt,
    videoWithoutBorders,
}: MediaProps): JSX.Element {
    if (!media?.type) {
        return null
    }

    if (media.type === "image") {
        return (
            <Image
                {...generateSanityImage({
                    image: media,
                    width: width,
                    height: height,
                    step,
                    minHeight,
                    minWidth,
                    format,
                })}
                ignorePlaceholder={ignorePlaceholder}
                alt={alt}
                sizes={sizes}
            />
        )
    }

    if (media.type === "video") {
        return (
            <VideoSmart {...media} containerSize={[width, height]} videoWithoutBorders={videoWithoutBorders} />
        )
    }
    if (media.type == "vimeoFiles") {
        return <VideoLightweight {...media} sizes={sizes} containerSize={[width, height]} />
    }

    throw new Error("Unsupported media type")
}
