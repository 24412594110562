import { Heading, Section } from "src/utils"
import React from "react"
import s from "./StepsModule.module.scss"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import { PageThemeType, SanityCallToAction, SanityRichText, ThemeColor } from "src/sanity/types"
import Steps from "./Steps"

export enum Direction {
    HORIZONTAL = "horizontal",
    VERTICAL = "vertical",
}

export interface Step {
    title: string
    text?: SanityRichText
    cta?: SanityCallToAction
    circleText: string
    _key?: string
}

export interface StepsModuleProps {
    title: string
    steps: Step[]
    direction: Direction
    circleColor: ThemeColor
    pageThemeType?: PageThemeType
    hasBackgroundColor?: boolean
}

export default function StepsModule(props: StepsModuleProps): JSX.Element {
    const { title, steps, direction, hasBackgroundColor = true } = props
    return (
        <>
            <div className={cn(s["steps-module"], hasBackgroundColor ? "has-background-color" : null)}>
                <Section>
                    <Container className={cn(s["steps-module"], s[`steps-module--direction-${direction}`])}>
                        {title?.length > 1  && (
                            <Heading className={cn(s["steps-module__title"], "heading-300")}>{title}</Heading>
                        )}

                        <Steps className={s["steps-module__steps"]} steps={steps} />
                    </Container>
                </Section>
            </div>
        </>
    )
}
